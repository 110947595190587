import React from 'react';
import { Router, useLocation } from '@reach/router';
import qs from 'qs';

import { Container, SEOPage } from "../../components";

import SearchApp from "cre-plugin/src/components/search";

import TransitionLink from "gatsby-plugin-transition-link"

const urlToSearchState = location => qs.parse(location.search.slice(1));

class DynamicPage extends React.Component {

  render() {

    const GetLocation = () => {
      const location = useLocation();
      urlToSearchState(location)
    }

    return (

      <Container breadcrumbs={true} breadData={{ type: "search" }} >

        <div>

          <SEOPage
            title="Vacancy search"
            keywords="search, vacancies"
            description="Search our extensive vacancy portfolio."
            url={`/app/search`}
          />

          <Router basepath="/app/">

            <SearchApp
              path="search"
              type="main"
              state={{
                searchState: GetLocation,
                refresh: false
              }} />

          </Router>

        </div>

      </Container>

    )
  }
}

export default DynamicPage