import React, { Fragment } from 'react';
import { Link } from '@reach/router';
import qs from 'qs';
import algoliasearch from 'algoliasearch/lite';

import { InstantSearch, Configure, RefinementList, ToggleRefinement } from 'react-instantsearch-dom';

import PropTypes from 'prop-types';

import {
  SearchBox,
  ClearRefinementList,
  GLAMaxRefinementList,
  GLAMinRefinementList,
  PriceMaxRefinementList,
  PriceMinRefinementList,
  DealSwitch3,
  DealSwitch2,
  Stats,
  HeroRefinementList3,
  HeroRefinementList2,
  Pagination,
  SearchResults,
} from './searchComponents';

import { orderBy } from 'lodash';

import { StyledPage, Grid32, Grid33, Grid37, Grid22, Grid41, StyledSearch, StyledHeroSearch } from "site/src/components/css";

const appId = process.env.GATSBY_ALGOLIA_APP_ID;
const searchKey = process.env.GATSBY_ALGOLIA_SEARCH_KEY;
const searchClient = algoliasearch(appId, searchKey);

const updateAfter = 700;

const createURL = state => `?${qs.stringify(state)}`;

const searchStateToUrl = (props, searchState) =>
  searchState ? `${props.location.pathname}${createURL(searchState)}` : '';

const urlToSearchState = location => qs.parse(location.search.slice(1));

class App extends React.Component {

  state = {
    searchState: {
      sortBy: "ToLet"
    },
    loading: true,
    open: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setState({
        searchState: urlToSearchState(this.props.location)
      });
    }
    localStorage.setItem('searchState', JSON.stringify(this.state.searchState));
  }

  componentDidMount() {

    this.setState({
      loading: false
    })

    //Empty URL
    if (this.props.type === "main" && this.props.location.search === "") {

      //Check if local stoage exists
      if (localStorage.getItem('searchState')) {

        //set searchState from localStorage
        this.setState({
          searchState: JSON.parse(localStorage.getItem('searchState'))
        })

        //Update URL
        this.props.navigate(
          searchStateToUrl(this.props, JSON.parse(localStorage.getItem('searchState'))),
          JSON.parse(localStorage.getItem('searchState'))
        );
      } else {

        //Set state initially declared
        this.setState({ searchState: this.state.searchState });

        //Update localStorage
        localStorage.setItem('searchState', JSON.stringify(this.state.searchState));
      }
    }

    //Non-empty URL
    if (this.props.type === "main" && this.props.location.search !== "") {

      //Set state from URL
      this.setState({
        searchState: urlToSearchState(this.props.location)
      });

      //Update localStorage
      localStorage.setItem('searchState', JSON.stringify(this.state.searchState));

    }

    //Hero
    if (this.props.type === "hero" && localStorage.getItem('searchState')) {

      this.setState({
        searchState: JSON.parse(localStorage.getItem('searchState'))
      })
    }

    this.interval = setInterval(
      () =>
        this.setState({ refresh: true }, () => {
          this.setState({ refresh: false });
        }),
      process.env.GATSBY_ALGOLIA_INTERVAL_MILLISEC
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onSearchStateChange = searchState => {

    clearTimeout(this.debouncedSetState);

    this.debouncedSetState = setTimeout(() => {
      if (this.props.type === "main") {

        this.props.navigate(
          searchStateToUrl(this.props, searchState),
          searchState
        );

      }
    }, updateAfter);
    this.setState({ searchState });
    localStorage.setItem('searchState', JSON.stringify(this.state.searchState));
  };

  onRefineMobile = (isOpen) => {
    this.setState({
      open: !isOpen
    })
  }

  render() {

    return (

      <StyledPage>

        <InstantSearch
          searchClient={searchClient}
          indexName={this.state.searchState.sortBy || "ToLet"}
          searchState={this.state.searchState}
          onSearchStateChange={this.onSearchStateChange}
          createURL={createURL}
          refresh={this.state.refresh}
        >

          {/* <Configure
            facetFilters="suburb_cluster:Midrand and surrounds"
          /> */}

          <form autoComplete="off">

            {
              this.props.type === "main" && (

                <StyledSearch>

                  <ToggleRefinement
                    defaultRefinement="Midrand and surrounds"
                    attribute="suburb_cluster"
                    value="Midrand and surrounds"
                  />

                  <h1>Work</h1>

                  <Grid37>

                    <button className={`mobileRefine ${this.state.open}`} onClick={
                      (e) => {
                        e.preventDefault();
                        this.onRefineMobile(this.state.open);
                      }
                    }>Refine your search</button>

                    <div className={`primarySearch ${this.state.open}`}>

                      <h5 className="searchTitle">Refine your search</h5>
                      {/* Page meta data (title, description, etc.)*/}

                      {/* <StyledInput>

                        <div className="searchBox">
                          <SearchBox
                            showLoadingIndicator
                          />
                        </div>

                      </StyledInput> */}

                      <Grid32 className="dealSwitch">

                        <span className="dealLabel">To Let</span>

                        <DealSwitch3
                          defaultRefinement="ToLet"
                          items={[
                            { value: 'ToLet' },
                            { value: 'ForSale' },
                          ]}
                        />

                        <span className="dealLabel">For sale</span>

                      </Grid32>

                      <HeroRefinementList3
                        attribute="suburb"
                        placeholder="Search by suburb"
                        transformItems={items => orderBy(items, "label", "asc")}
                        limit={100}
                      />

                      <HeroRefinementList3
                        attribute="property_category"
                        transformItems={items => orderBy(items, "label", "asc")}
                      />

                      <Grid22>

                        <GLAMinRefinementList
                          attribute="min_gla"
                          min={0}
                          max={10000000000}
                          deal={this.state.searchState.sortBy || "ToLet"}
                        />

                        <GLAMaxRefinementList
                          attribute="max_gla"
                          min={0}
                          max={10000000000}
                          deal={this.state.searchState.sortBy || "ToLet"}
                        />

                        <PriceMinRefinementList
                          attribute="gross_price"
                          min={0}
                          max={10000000000}
                          deal={this.state.searchState.sortBy || "ToLet"}
                        />

                        <PriceMaxRefinementList
                          attribute="gross_price"
                          min={0}
                          max={10000000000}
                          deal={this.state.searchState.sortBy || "ToLet"}
                        />

                      </Grid22>

                      <button className="mainSearchBtn" onClick={
                        (e) => {
                          e.preventDefault();
                          this.onRefineMobile(this.state.open);
                        }
                      }>
                        Apply filters
                      </button>

                      {/* <ClearRefinementList
                        clearsQuery
                      /> */}

                    </div>

                    {/* <Stats filters={this.state.searchState} /> */}

                    {
                      !this.state.loading && (

                        <div>

                          <SearchResults type={this.state.searchState.sortBy || "ToLet"} />

                        </div>

                      )
                    }
                    {
                      this.state.loading && (

                        <div className="loader"></div>

                      )
                    }

                  </Grid37>

                  <div>

                    <Pagination

                    />

                  </div>

                </StyledSearch>
              )
            }

            {/* Hero search */}

            {/* <StyledHeroSearch>

              {
                this.props.type === "hero" && !this.state.loading && (

                  <Fragment>

                    <div className="searchBox">
                      <SearchBox
                        showLoadingIndicator
                      />
                    </div>

                    <div>
                      <Stats filters={this.state.searchState} />
                    </div>

                    <Grid41>

                      <DealSwitch2
                        defaultRefinement="ToLet"
                        items={[
                          { value: 'ToLet' },
                          { value: 'ForSale' },
                        ]}
                      />

                      <HeroRefinementList2
                        attribute="suburb_cluster"
                        placeholder="Suburb"
                        transformItems={items => orderBy(items, "label", "asc")}
                        limit={100}
                      />

                      <HeroRefinementList2
                        attribute="property_category"
                        placeholder="Category"
                        transformItems={items => orderBy(items, "label", "asc")}
                      />

                      <div>
                        <Link to="/app/search"><button>Search</button></Link>
                      </div>

                    </Grid41>

                  </Fragment>

                )
              }

              {
                this.props.type === "hero" && this.state.loading && (

                  <div className="loader"></div>

                )

              }

            </StyledHeroSearch> */}

          </form>

        </InstantSearch>

      </StyledPage>

    )
  }
}

App.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  })
};

export default App;